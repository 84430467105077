<template>
  <div
    style="margin-top:0px;"
  >
    <div>
      <b-link
        :to="{ name: 'partner' }"
        class="font-weight-bold d-block text-nowrap"
      >
        <feather-icon
          icon="ArrowLeftCircleIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span>
          Zurück zum Partner list
        </span>
      </b-link>
      <br>
    </div>
    <validation-observer ref="validatePartnerForm">
      <b-form
        @submit.prevent="onSubmitData"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex">
                <feather-icon
                  icon="UserIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  Partner Daten
                </h4>
              </div>
              <br>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Partner title"
                    label-for="partnerTitle"
                  >
                    <b-form-input
                      id="partnerTitle"
                      v-model="partnerData.title"
                      trim
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Bonus title"
                    label-for="partnerBonusTitle"
                  >
                    <b-form-input
                      id="partnerBonusTitle"
                      v-model="partnerData.partnerBonusTitle"
                      trim
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Bonus type"
                    label-for="bonusType"
                  >
                    <v-select
                      id="bonusType"
                      v-model="partnerData.bonusType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="bonusTypeOptions"
                      :reduce="option => option.id"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Bonus amount"
                    label-for="bonusValue"
                  >
                    <b-form-input
                      id="bonusValue"
                      v-model="partnerData.bonusValue"
                      trim
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="submit"
                    @click.prevent="validateForm"
                  >
                    Speichern
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import {
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import partnerStoreModule from '@/views/partner/partnerStoreModule'
import router from '@/router'

export default {
  components: {
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BButton,
    vSelect,
    ValidationObserver,
  },
  directives: {},
  methods: {
    validateForm() {
      this.$refs.validatePartnerForm.validate().then(ok => {
        if (ok) {
          this.onSubmitData()
        }
      })
    },
  },
  setup() {
    const toast = useToast()
    const PARTNER_APP_STORE_MODULE_NAME = 'app-partner'

    // Register module
    if (!store.hasModule(PARTNER_APP_STORE_MODULE_NAME)) store.registerModule(PARTNER_APP_STORE_MODULE_NAME, partnerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PARTNER_APP_STORE_MODULE_NAME)) store.unregisterModule(PARTNER_APP_STORE_MODULE_NAME)
    })

    const partnerData = ref({})
    const bonusTypeOptions = [{
      label: 'Percentage',
      id: 1,
    }, {
      label: 'Fixed amount',
      id: 2,
    }, {
      label: 'Null',
      id: 3,
    }]

    const onSubmitData = () => {
      store.dispatch('app-partner/savePartner', {
        id: router.currentRoute.params.partnerId,
        partnerData,
      })
        .then(response => {
          if (response.status === 200) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Erfolg',
                text: 'Daten erfolgreich aktualisiert.',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Fehler',
                text: 'Fehler beim Aktualisieren.',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            })
          }
        })
        .catch(e => {
          console.log('ERROR: ', e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fehler',
              text: 'Fehler beim Aktualisieren.',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          })
        })
    }

    store.dispatch('app-partner/fetchPartnerDetail', { id: router.currentRoute.params.partnerId })
      .then(response => {
        if (response.status === 200) {
          partnerData.value = response.data
        }
      })

    return {
      partnerData,
      bonusTypeOptions,
      onSubmitData,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
